.Toastify__toast--success {
  color: white;
  background-color: #01a57c;
  border-radius: 5px;
}

.Toastify__toast--error {
  color: white;
  background-color: #be0000;
  border-radius: 5px;
}
