body {
  margin: 0;
  padding: 0;
  font-family: "Noto Sans JP", sans-serif !important;
  background-color: #494454 !important;
  color: #1d1927 !important;
}

.fontFamily {
  font-family: "Noto Sans JP", sans-serif !important;
}

.Bold {
  font-family: "Noto Sans JP", sans-serif !important;
  font-weight: 700 !important;
}

.ExtraBold {
  font-family: "Noto Sans JP", sans-serif !important;
  font-weight: 900 !important;
}
