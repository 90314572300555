.darkBackground {
  background-color: #1d1927 !important;
}

.user-select {
  min-width: 130px !important;
}

.pointer {
  cursor: pointer !important;
}

.commentDropZone {
  height: auto !important;
  margin-top: 1em !important;
  margin-bottom: 1em !important;
  background-color: #ffffff !important;
}

.projectFilesDropZone {
  height: 450px;
  width: 100%;
  position: relative;
  padding: none;
  margin: -0.8% 0px 0px 0px;
}

.taskDetailsDropZone {
  height: auto !important;
  width: 100% !important;
  background-color: #ffffff !important;
}

.blankDropZone {
  border: 1px dashed rgba(34, 36, 38, 0.15) !important;
  border-radius: 0.2rem !important;
}

.fileListItem {
  display: inline-flex !important;
  padding-right: 5px !important;
  padding-bottom: 1.5px !important;
}

.fileGrid {
  border: 1px solid #e4e4e4 !important;
  border-radius: 5px !important;
  background-color: #fcf7ff !important;
}

.fileListHeader {
  font-size: 0.85em !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.fileListDescription {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  color: #a4a4a4 !important;
  font-size: 0.75em !important;
}

.workLogTableDiv {
  height: 350px;
  overflow-y: auto;
}

/* Chrome, Safari, Edge, Opera */
.number-input::-webkit-outer-spin-button,
.number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.darkPlaceholder > .text {
  color: #1d1927 !important;
}

/* Firefox */
.number-input[type="number"] {
  -moz-appearance: textfield;
}

.notificationDropdown {
  font-size: 1.5em !important;
  margin-top: -12px !important;
  margin-left: 2px !important;
}

.notificationDropdownItem {
  border-bottom: 1px solid #fcf7ff !important;
  padding: 1em !important;
}
.rdt_TableBody {
  overflow-y: auto !important;
  height: calc(100vh - 270px);
}

/* .taskFormContainer {
  width: 30%;
} */
.ui.dropdown > .visible.menu .scrolling.menu {
  border: none !important;
}

.customGrid {
  margin: 0 !important;
  height: calc(100vh - 52px) !important;
}

.gridRow {
  margin: 0 !important;
  padding: 0.5em 0 !important;
}
.gridColumn {
  padding: 0 0.5em !important;
}

.subTitle {
  color: #1d1927 !important;
  font-size: 0.9em !important;
}

.addTaskInput {
  width: 70% !important;
}

/* .ui.inline.dropdown > .text {
  font-weight: 400 !important;
} */

.taskFilterDropdown > .text {
  font-size: 1.2em !important;
  font-weight: 900 !important;
}

.assigneeDropdown > .text {
  font-weight: 400 !important;
}

.taskFilterDropdown .dropdown.icon {
  margin-top: 0.6em !important;
}

.inputDate {
  border: none;
  outline: none;
  line-height: 1.3;
}
.inputDate:focus {
  border: transparent !important;
  outline: none;
}

.fileListDropInActive {
  border: 1px dashed rgba(34, 36, 38, 0.15);
  border-radius: 0.2rem;
}

.searchDropdown {
  border: none !important;
}

.searchDropdown > i {
  display: none !important;
}

.selected-project,
.selected-project:hover {
  background-color: #0b2ada !important;
}

/* .selected-project:hover,
.selected-project:active {
  background-color: #0b2ada !important;
} */
.selected-project span {
  color: #ffffff;
}

.searchDropdown > input:focus,
.searchDropdown {
  box-shadow: none !important;
}

.active-search > input {
  background-color: #ffffd4 !important;
}

.bdkqKp {
  display: block !important;
}
/* .sc-bdfBwQ {
  margin: 0 !important;
} */

.sc-higXBA {
  padding-bottom: 0px !important;
}

.ksBfCV {
  padding-bottom: 0px !important;
}

.dropZoneFocus:focus {
  outline: none !important;
}

.labelValueAlignment {
  /* border: 1px solid black !important; */
  margin-left: 2.7em !important;
}

/* .taskDetailsdropzone {
  margin-left: 1.1em !important;
  margin-right: 0.4em;
} */

.searchInput {
  width: 210px;
}

.comments-label {
  margin: 0 !important;
  margin-top: 0.1em !important;
  margin-right: 30px !important;
}

.files-label {
  margin: 0 !important;
  margin-top: 0.1em !important;
  margin-right: 30px !important;
}

.due-date-label {
  margin: 0 !important;
  margin-top: 0.55rem !important;
}

.empty-due-date {
  margin-top: 0.55em !important;
  margin-left: 78px !important;
}

.labels-container {
  min-width: 332px;
  padding-bottom: 10px;
  padding-left: 13px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.assignee-container {
  min-width: 90px;
  padding-bottom: 10px;
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
}

.assignee-container.collaborators {
  padding-right: 5px;
}

.assignee-container.assignee {
  padding-right: 10px;
}

.expired-due-date {
  color: #be0000;
}

.completed-due-date {
  color: #00ca99;
}

.searchInput .prompt {
  width: 210px !important;
}

.searchInputFocus {
  width: 420px;
}

.searchInputFocus .prompt {
  width: 420px !important;
}

.permissionGrid {
  background-color: #f9fafb;
  border: 2px solid rgba(34, 36, 38, 0.15);
  border-radius: 5px;
}

.darkColumn {
  background-color: #e8e8e8;
}

.ui.toggle.checkbox.custom input:focus:checked ~ .box:before,
.ui.toggle.checkbox.custom input:focus:checked ~ label:before {
  background-color: #0b2ada !important;
}

.ui.toggle.checkbox.custom input:checked ~ .box:before,
.ui.toggle.checkbox.custom input:checked ~ label:before {
  background-color: #0b2ada !important;
}

/* .rdt_TableBody {
  border: 1px solid black !important;
  height: inherit !important;
} */

/* .gQHdOi div:first-child {
  white-space: unset !important;
  text-overflow: unset !important;
  overflow: unset !important;
} */

.blank-project-container .segment {
  background-color: #fff !important;
}

.blank-project-container .blank-project {
  height: 200px !important;
  width: 300px !important;
}

.blank-project-container .header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blank-files-container {
  background-color: #fff !important;
}
.blank-files-container .header {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

.blank-files-container .header .blank-files {
  width: 80% !important;
}

.ui.modal > .close {
  top: 1.0535rem !important;
  right: 1rem !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 1300px) {
  .dropdownText {
    word-wrap: break-word;
    white-space: normal;
  }

  .sc-hHftDr {
    padding-bottom: 0 !important;
  }

  .card {
    box-shadow: 0 1px 2px #ccc !important;
    border: 0px !important;
    color: #1d1927 !important;
  }

  .fileSectionDragDropActive {
    /* position: relative; */
    /* width: 200px; */
    z-index: 2;
    /* visibility: visible; */
    /* height: 200px; */
    /* display: inline-flex; */
    border-width: 3px;
    border-color: rgb(102, 102, 102);
    border-style: dashed;
    border-radius: 5px;
  }

  .fileSectionDragDropInActive {
    z-index: 0;
    /* display: none; */
    /* visibility: hidden; */
    border: "none";
  }
  table {
    position: relative;
    border-collapse: collapse;
  }
  th {
    position: sticky;
    top: 0;
  }

  .dropdownSearch > a {
    font-size: 0.6em !important;
  }

  .addTaskDragDropActive {
    border: "2px dashed black";
  }
  .addTaskDragDropInActive {
    border: "2px dashed black";
  }

  .addTaskInput {
    width: 84.5% !important;
  }

  .subTaskContainer.Update .addTaskInput {
    width: 70% !important;
  }

  .subTaskContainer.Add .addTaskInput {
    width: 88.5% !important;
  }

  .subTaskCheckList label {
    margin-bottom: 0 !important;
  }

  .dropZoneFocus:focus {
    outline: none !important;
  }

  /* .ui.dropdown {
    opacity: 1 !important;
    z-index: 1 !important;
    position: relative !important;
  } */
}

.pointing {
  height: 0 !important;
  display: flex !important;
}

@media only screen and (min-width: 1200) {
  .addTaskInput {
    width: 70% !important;
  }

  .dropZoneFocus:focus {
    outline: none !important;
  }

  /* .ui.dropdown {
    opacity: 1 !important;
    z-index: 1 !important;
    position: relative !important;
  } */
}

@media only screen and (max-width: 1450px) {
  .addTaskInput {
    width: 82.5% !important;
  }

  .dropZoneFocus:focus {
    outline: none !important;
  }
  .comments-label {
    margin-right: 18px !important;
  }

  .files-label {
    margin-right: 18px !important;
  }

  .labels-container {
    min-width: 304px;
  }

  .assignee-container {
    min-width: 90px;
  }

  /* .ui.dropdown {
    opacity: 1 !important;
    z-index: 1 !important;
    position: relative !important;
  } */
}

@media only screen and (min-width: 1470px) and (max-width: 1500px) {
  .comments-label {
    margin-right: 25px;
  }

  .files-label {
    margin-right: 25px;
  }

  .labels-container {
    min-width: 327px;
  }

  .assignee-container {
    min-width: 80px;
  }
}
@media only screen and (min-width: 1350px) and (max-width: 1400px) {
  .comments-label {
    margin-right: 20px;
  }

  .files-label {
    margin-right: 20px;
  }

  .labels-container {
    min-width: 302px;
  }

  .assignee-container {
    min-width: 70px;
  }
}
@media only screen and (min-width: 1450px) and (max-width: 1470px) {
  .labels-container {
    min-width: 332px;
  }

  .assignee-container {
    min-width: 70px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1450px) {
  .labels-container {
    min-width: 312px;
  }

  .assignee-container {
    min-width: 70px;
  }
}
.ui.fluid.search .results {
  max-height: calc(100vh - 60px);
  overflow: auto;
}

.subtask-actions {
  position: absolute;
  /* top: 0px !important; */
  right: 0px !important;
}

.slide-pane__title-wrapper {
  margin-left: 10px !important;
}

/* .new-comment {
  background-color: rgb(255, 157, 30);
} */
.old-comment {
  background-color: #0b2ada;
}
.comment-wrapper {
  position: fixed;
  height: 3.5em !important;
  width: 3.5em !important;
  bottom: 3em;
  right: 3em;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  text-align: center;
  align-items: center;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  color: white !important;
  justify-content: center;
}
.floating-comment {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(132deg)
    brightness(103%) contrast(103%);
}

.comment-count-label {
  position: fixed;
  bottom: 6.8em;
  right: 3.5em;
  cursor: pointer;
}

.filter-side-panel {
  position: fixed;
  top: 12vh;
  left: 11vw;
}

.ui.comments .comment .avatar img,
.ui.comments .comment img.avatar {
  height: 2em !important;
  width: 2em !important;
  border-radius: 400rem !important;
  display: inline-block !important;
}

/* .ui.dropdown:not(.button)>.default.text{
  margin-left: -0.4em !important;
} */

.priorityDropdown,
.progressDropdown,
.assigneeFilterDropdown,
.collaboratorsDropdown {
  margin-left: -0.9em !important;
  font-size: 0.9em !important;
}

.filterDatePlaceholder {
  color: rgba(191, 191, 191, 0.87);
}

.filterDatePlaceholder:hover {
  color: #1d1927;
}

.taskDetailActiveFilesDropZone {
  position: fixed;
  height: 90vh;
  width: 68vw;
  overflow-y: auto;
  z-index: 1;
  border-width: 3px;
  border-color: rgb(102, 102, 102);
  border-style: dashed;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.3);
}
.taskDetailInActiveFilesDropZone {
  position: fixed;
  height: 90vh;
  width: 68vw;
  overflow-y: auto;
}
.taskDetailfileSectionDragDropActive {
  z-index: 999999;
  /* visibility: visible; */
  /* height: 200px; */
  /* display: inline-flex; */
  border-width: 3px;
  border-color: rgb(102, 102, 102);
  border-style: dashed;
  border-radius: 5px;
}

.ui.selection.active.dropdown .menu {
  border: none !important;
}

.yellowIcon {
  filter: invert(70%) sepia(68%) saturate(478%) hue-rotate(344deg)
    brightness(101%) contrast(101%);
}

.greyIcon {
  filter: invert(62%) sepia(3%) saturate(118%) hue-rotate(251deg)
    brightness(84%) contrast(94%);
}

.whiteIcon {
  filter: invert(100%) sepia(3%) saturate(118%) hue-rotate(251deg)
    brightness(84%) contrast(94%);
}

.blueIcon {
  filter: invert(14%) sepia(95%) saturate(4763%) hue-rotate(234deg)
    brightness(85%) contrast(102%);
}

.slide-pane__overlay.overlay-after-open {
  z-index: 5 !important;
}
/* .panelClass {
  margin-top:52px;
} */

.commentText {
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 3em !important;
  max-width: 24.5em !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.notificationText {
  display: block;
  overflow: auto;
  white-space: normal;
}

.inputAddTask {
  background-color: #fff;
  font-size: 1em;
  padding: 7px 0px 7px 17px;
}

.headerMenu {
  padding-left: 0.3em;
  padding-right: 0.5em;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.headerImage {
  margin-right: 2em;
}

.ui.menu {
  margin: 0 !important;
}

.ui.dropdown .scrolling.menu > .item.item.item.selected-project,
.ui.dropdown .scrolling.menu > .item.item.item.selected-project:hover {
  background-color: #0b2ada !important;
}

.notificationDropdownMenu {
  font-size: 1rem !important;
  /* margin-top:1.4em !important; */
}

.ui.floating.dropdown > .menu.notificationDropdownMenu {
  margin-top: 1em !important;
  max-height: 38em !important;
}

.ui.secondary.menu .item {
  padding: 0.5em 0.92857143em !important;
}

.ui.input > input#inputAddTask::-webkit-input-placeholder {
  /* color: #868586; */
  color: rgb(105, 105, 105);
  font-weight: 1000 !important;
  font-family: "Noto Sans JP", sans-serif !important;
  /* font-weight: 1000 !important;
  font-size: 16 !important; */
}
.ui.input > input#inputAddTask::-moz-placeholder {
  color: rgb(105, 105, 105);
  font-weight: 1000 !important;
  font-family: "Noto Sans JP", sans-serif !important;
}
.ui.input > input#inputAddTask:-ms-input-placeholder {
  color: rgb(105, 105, 105);
  font-weight: 1000 !important;
  font-family: "Noto Sans JP", sans-serif !important;
}

.ui.floating.dropdown > .menu.notificationDropdownMenu > .item.notificationRead,
.ui.floating.dropdown
  > .menu.notificationDropdownMenu
  > .item.notificationRead:hover {
  background-color: white !important;
}

.ui.floating.dropdown
  > .menu.notificationDropdownMenu
  > .item.notificationUnread,
.ui.floating.dropdown
  > .menu.notificationDropdownMenu
  > .item.notificationUnread:hover {
  background-color: rgba(0, 160, 250, 0.1) !important;
}

.descriptionLink {
  font-weight: 500 !important;
  cursor: pointer;
  color: #1d1927;
  font-style: italic;
}
.ql-container {
  /* min-height: 10em; */
  border-bottom-left-radius: 0.28571429rem;
  border-bottom-right-radius: 0.28571429rem;
  background: #ffffff;
  /* height: 150px; */
}

.ql-toolbar {
  background: #ffffff;
  border-top-left-radius: 0.28571429rem;
  border-top-right-radius: 0.28571429rem;
  border-bottom: none;
}

.quill-image {
  max-height: 100px;
  max-width: 100px;
}

.ui.form input[type="text"] {
  width: inherit !important;
}

.ql-tooltip {
  left: unset !important;
}

.ql-snow .ql-tooltip input[type="text"] {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  line-height: 1.21428571em;
  padding: 0.67857143em 1em;
  font-size: 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  transition: color 0.1s ease, border-color 0.1s ease;
}

.ql-snow .ql-tooltip input[type="text"]:focus {
  color: rgba(0, 0, 0, 0.95);
  border-color: #85b7d9;
  border-radius: 0.28571429rem;
  background: #fff;
  box-shadow: 0 0 0 0 rgb(34 36 38 / 35%) inset;
}

.pg-viewer-wrapper {
  overflow-y: unset !important;
}

.photo-viewer-container {
  width: unset !important;
  height: unset !important;
}

.photo-viewer-container > img {
  width: unset !important;
  height: unset !important;
  max-height: 30em !important;
  max-width: 30em !important;
}

.quill.ql-html {
  margin-top: 0.25em !important;
}
.ql-editor.ql-html {
  padding: 0 !important;
  background-color: transparent !important;
}

.ql-container.ql-snow.ql-html {
  border: none !important;
  height: unset !important;
  max-height: 10em !important;
  overflow-y: auto !important;
  border-radius: 0 !important;
  background-color: transparent !important;
}

.taskDescription {
  width: 61.35vw !important;
}

.ql-container.ql-snow.ql-html.ql-comment {
  max-height: unset !important;
}

#comments.quill .ql-container {
  height: 100px !important;
}
#description.quill .ql-container {
  height: 100px !important;
}
#logWork.quill .ql-container {
  height: 110px !important;
}
#noteDescription.quill .ql-container {
  height: 150px !important;
}
#projectDescription.quill .ql-container {
  height: 100px !important;
}
.upload-icon {
  margin-top: 0.55em !important;
}

.taskAvatar {
  width: 2.1em !important;
  height: 2.1em !important;
  font-size: 0.7em !important;
  line-height: 2.1em !important;
  display: inline-block !important;
}

.taskAvatar.collaborators {
  margin-right: 0.6em !important;
}

.taskAvatar.collaborators-img {
  margin-right: 0.35em !important;
}

.taskAvatar.dropdown {
  margin-right: 0.5rem;
  width: 2.1em !important;
  height: 2.1em !important;
  font-size: 0.9em !important;
  line-height: 2.1em !important;
  display: inline-block !important;
}

.taskAvatar.dropdown.assignee {
  width: 1.45rem !important;
  height: 1.45rem !important;
  line-height: 1.45rem !important;
  margin-right: 0.1rem !important;
  font-size: 0.7em !important;
}
/* .taskAvatar.collaboratorsDropdown {
  width: 2.1em !important;
  height: 2.1em !important;
  font-size: 0.7em !important;
  line-height: 2.1em !important;
  display: inline-block !important;
  margin-right: 0.78571429rem !important;
} */
/* .taskAvatar.filterdropdown {
  margin-right: 0.1rem;
  font-size: 0.9em !important;
} */
/* .taskAvatar.assignee {
  height: 1.9em !important;
  width: 1.9em !important;
  line-height: 1.9em !important;
  font-size: 0.9em !important;
  margin-right: 0.3em !important;
} */
/* .taskAvatar.filter {
  width: 2.1em !important;
  height: 2.1em !important;
  font-size: 0.9em !important;
  line-height: 2.1em !important;
  display: inline-block !important;
} */
.image-dropdown-item {
  display: flex;
  align-items: center;
}

/* .ui.label > img {
  width: 2em !important;
  height: 2em !important;
  border-radius: 50%;
} */
.image-dropdown-item .dropdown-img {
  border: 1px solid rgba(34, 36, 38, 0.15);
  height: 2em;
  width: 2em;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.dropdown-img {
  border: 1px solid rgba(34, 36, 38, 0.15);
  height: 2em;
  width: 2em;
  margin-right: 0.3rem;
  border-radius: 50%;
}

.dropdown-img.assignee {
  max-height: 1.45rem;
  max-width: 1.45rem;
  margin-right: 0.1rem;
}

.note-loader-image {
  height: 2em !important;
  width: 2em !important;
  margin-left: 3em;
  /* margin-top: 0.6em; */
}

.note-loader-image.first-image {
  margin-left: 5em !important;
}

.work-log-description:hover {
  background-color: rgb(187, 204, 221);
}
#header-bar {
  display: none;
}
#file-name {
  display: none;
}
#image-renderer {
  background-blend-mode: color;
}
.hpigfk {
  height: 800px !important;
}

/* #image-img{
  max-height: 700px!important;
  max-width: 700px !important;
} */
#no-renderer-download {
  display: none;
}
#no-renderer {
  width: 100%;
  font-size: 1.5em;
  font-weight: bold;
  font-family: "Noto Sans JP", sans-serif !important;
  background-color: #fff;
}
#pdf-download {
  display: none !important;
}
#msdoc-renderer {
  height: 30em;
}
#html-body {
  height: 30em;
  width: 100%;
  overflow-y: auto;
  display: none;
}
/* .assignee-img {
  border: 1px solid rgba(34, 36, 38, 0.15);
  height: 1.8em;
  width: 1.8em;
  margin-right: 0.3rem;
  border-radius: 50%;
} */

/* .collaborator-label-text {
  text-overflow: ellipsis;
  overflow: hidden;
  min-height: auto;
  min-width: auto;
  max-width: 6em !important;
  max-height: 4em !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
} */

/* .collaborator-label-text.assignee-label {
  max-width: 8em !important;
  -webkit-line-clamp: 1 !important;
}

.collaborator-label-text.filter {
  max-width: 10em !important;
} */
.dropdown-username {
  text-overflow: ellipsis;
  overflow: hidden;
  min-height: auto;
  min-width: auto;
  max-width: 8em !important;
  max-height: 8em !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
}

.dropdown-username.menu {
  -webkit-line-clamp: 1 !important;
}
/* .collaboratorsDropdown.ui.dropdown .menu > .item {
  line-height: 1.6em;
}

.assigneeFilterDropdown.ui.dropdown .menu > .item {
  line-height: 2em;
}
.assigneeDropdown.ui.dropdown .menu > .item {
  line-height: 1.2em;
} */
/* .ui.multiple.dropdown > .label {
  width: fit-content;
  display: flex;
} */
/* .ui.label .taskAvatar.collaborator-dropdown {
  height: 2em !important;
  width: 2em !important;
  line-height: 2em !important;
  border-radius: 50%;
  font-size: 0.9em !important;
  margin-right: 0.3rem !important;
}
.ui.label {
  line-height: 1.3em;
} */

.react-select__dropdown-indicator {
  display: none !important;
}
.react-select__value-container {
  padding: 0 !important;
}
.react-select__menu {
  z-index: 10 !important;
}
.image.header {
  border-radius: 50% !important;
}
.loader-image {
  border-radius: 50%;
  height: 3em;
  width: 3em;
  margin-left: 1em;
}

.accordion-image {
  /* height: 3.8rem !important; */
  /* margin-bottom: 0.15rem; */
  width: 1.4em !important;
  height: 1.4em !important;
  border-radius: 50%;
  margin-top: 0.2em;
  /* border-top: 1px solid white;
  border-bottom: 1px solid white; */
}

.accordion-loader {
  margin-top: 3em;
}
.accordion-segment {
  height: 3em;
  width: 87%;
  margin-left: 1.65em;
}

.accordion-loader-second-row {
  margin-bottom: 0.8rem;
  margin-top: -0.5em;
  /* background-color: blue; */
}
.accordion-loader-grid {
  margin-left: 0.5rem !important;
}

.header-loader-logo {
  border-radius: 50%;
  height: 2.5em;
  width: 2.5em;
  /* margin-left: 2.8em; */
  /* margin-top: -0.2em; */
  margin-left: 0.5em;
}
.header-loader-user-avatar {
  border-radius: 50%;
  height: 1.8em;
  width: 1.8em;
  /* margin-left: 4.2em; */
  margin-left: -2em;
  /* margin-top: 0.3em; */
}
.header-placeholder-line {
  margin-top: 0.5rem !important;
  height: 10px !important;
  width: 10px !important;
}
.header-loader-line {
  height: 0.8em;
  width: 5em;
  /* margin-top: 0.4em; */
  margin-left: -1.7em !important;
}
.header-loader-line.project-dropdown {
  width: 9em;
  margin-left: -0.5em !important;
}
.header-loader-line.icons {
  width: 7em;
  margin-left: -1.5em !important;
}
.header-loader-line.search {
  width: 14em;
  margin-left: 34em !important;
}
.header-loader-line.notification-icons {
  width: 8em;
}
.header-loader-line.user-dropdown {
  width: 4em;
}

.profile-grid {
  margin-left: 0.05rem !important;
}

.profile-row {
  padding-top: 0.3rem !important;
  padding-bottom: 0.3rem !important;
}
.avatar-section {
  padding-bottom: 1rem !important;
  margin-bottom: 1.5rem !important;
}
.taskname-title {
  display: flex;
  justify-content: space-between;
  width: 27vw !important;
}
.taskName-title-label {
  margin-top: 2px;
}
.accordion-menu {
  width: 100%;
  height: fit-content;
  box-shadow: none;
  border-left: 0px !important;
  border-right: 0px !important;
  margin: 0 !important;
}
.accordion-menu-item {
  background-color: #fcf7ff;
  padding: 0px !important;
}
.task-avatar-image {
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
}
.inactive-task-card {
  background-color: white;
}
.active-task-card {
  background-color: rgb(0 160 250 / 0.1) !important;
}
.ui.avatar.image.task-avatar-image {
  margin-left: 0.3em !important;
  margin-right: -0.1em;
}
.ui.avatar.image.task-avatar-image.collaborators {
  margin-right: 0.55em !important;
}

.task-detail-section {
  height: calc(100vh - 70px);
  overflow-y: auto;
}

.header-avatar {
  line-height: 30px !important;
}

.task-icon {
  display: inline-block !important;
  margin-top: 0.3em !important;
}
.task-counts {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5em !important;
}
.task-count-label {
  /* margin-top: 0.2em !important; */
  margin-bottom: 0.05em !important;
  margin-left: 0.2em !important;
}
/* .ui.avatar.image.task-avatar-image{
  margin-right:0.45em;
} */

/* .react-select__indicator{
  display:none !important;
} */

/* .assignee-label{
  max-height: 1.9em;
  overflow:hidden;

} */
/* ::-webkit-input-placeholder {
  color:blue !important;
  font-weight: 1000 !important;
  font-size: 1em !important;
}
::-webkit-input-placeholder {
  font-weight: 1000 !important;
  font-size: 1em !important;
}
:-ms-input-placeholder {
  font-weight: 1000 !important;
  font-size: 1em !important;
} */
